import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc, updateDoc, increment } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB6SW-3x96Utl0TNXmXxbSRkORwLmdRgY8",
  authDomain: "forgive-app.firebaseapp.com",
  projectId: "forgive-app",
  storageBucket: "forgive-app.appspot.com",
  messagingSenderId: "325582350534",
  appId: "1:325582350534:web:bfb6b4f266ac5cf9178fc7",
  measurementId: "G-5Q9H4ZKMQT"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth, signInWithEmailAndPassword, doc, getDoc, setDoc, updateDoc, increment };
