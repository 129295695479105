import React, { useState, useRef, useEffect } from 'react';
import HomePage from './Homepage';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { FaSun, FaMoon, FaPlay, FaStop } from 'react-icons/fa';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }
`;

const lightTheme = {
  background: '#f5f5f5',
  color: '#333',
  buttonBackground: '#007bff',
  buttonColor: '#fff',
  inputBackground: '#fff',
  inputColor: '#333',
  borderColor: '#ccc'
};

const darkTheme = {
  background: '#333',
  color: '#f5f5f5',
  buttonBackground: '#444',
  buttonColor: '#fff',
  inputBackground: '#444',
  inputColor: '#f5f5f5',
  borderColor: '#555'
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  transition: background-color 0.3s, color 0.3s;
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  background-color: ${({ theme }) => theme.background};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  z-index: 1000;
`;

const Main = styled.main`
  margin-top: 60px;
  width: 100%;
  max-width: 600px;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonColor};
  border: none;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    opacity: 0.8;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

function App() {
  const [theme, setTheme] = useState(lightTheme);
  const [language, setLanguage] = useState('en');
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const toggleTheme = () => {
    setTheme(theme === lightTheme ? darkTheme : lightTheme);
  };

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'tr' : 'en');
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const playAudio = async () => {
      try {
        await audioRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.log('User interaction is required to play the audio:', error);
      }
    };
    playAudio();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header>
          <Button onClick={handlePlayPause}>
            {isPlaying ? <FaStop /> : <FaPlay />}
          </Button>
          <Button onClick={toggleTheme}>
            {theme === lightTheme ? <FaMoon /> : <FaSun />}
          </Button>
          <Button onClick={toggleLanguage}>
            {language === 'en' ? 'Türkçe' : 'English'}
          </Button>
        </Header>
        <Main>
          <HomePage language={language} />
          <audio ref={audioRef} src={`${process.env.PUBLIC_URL}/still-loving-you.mp3`} loop />
        </Main>
      </Container>
    </ThemeProvider>
  );
}

export default App;
