import { useState, useEffect, useRef } from 'react';
import { db, auth, signInWithEmailAndPassword, doc, getDoc, setDoc, updateDoc, increment } from './firebaseConfig';
import styled from 'styled-components';
import Confetti from 'react-confetti';
import useWindowSize from './useWindowSize';

const Container = styled.div`
  text-align: center;
  padding: 50px;
  border: 2px solid ${({ theme }) => theme.borderColor};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.background === '#f5f5f5' ? '#fff' : '#444'};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
`;

const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 24px;
`;

const VoteButton = styled.button`
  background-color: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonColor};
  border: none;
  padding: 10px 20px;
  margin: 20px 0;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

const AuthContainer = styled.div`
  margin-top: 20px;
`;

const Input = styled.input`
  padding: 10px;
  margin-right: 10px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputColor};
`;

const ForgiveMessage = styled.div`
  font-size: 20px;
  color: green;
  margin-top: 20px;
`;

const messages = {
  en: {
    title: "Should Beyza Forgive Burak?",
    forgiveButton: "Forgive",
    totalVotes: "Total forgiveness",
    forgiveMessage: "Beyza forgave!",
    verifyButton: "Forgive Burak! (Only Beyza can)",
    placeholder: "Beyza is expected.",
    loading: "Loading...",
    onlyBeyza: "Only Beyza can forgive."
  },
  tr: {
    title: "Beyza, Burak'ı Affetsin mi?",
    forgiveButton: "Affetsin",
    totalVotes: "Toplam affet sayısı",
    forgiveMessage: "Beyza affetti!",
    verifyButton: "Burak'ı affet! (Sadece Beyza)",
    placeholder: "Beyza bekleniyor.",
    loading: "Yükleniyor...",
    onlyBeyza: "Yalnızca Beyza affedebilir."
  }
};

const HomePage = ({ language }) => {
  const [count, setCount] = useState(null);
  const [beyzaAuth, setBeyzaAuth] = useState(false);
  const [inputCode, setInputCode] = useState('');
  const [forgiven, setForgiven] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const { width, height } = useWindowSize();
  const audioRef = useRef(null);

  const beyzaEmail = "cicurit@gmail.com";

  useEffect(() => {
    const fetchCount = async () => {
      const docRef = doc(db, 'votes', 'counter');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setCount(docSnap.data().count);
      } else {
        await setDoc(docRef, { count: 0 });
        setCount(0);
      }
    };
    fetchCount();
  }, []);

  const handleVote = async () => {
    const docRef = doc(db, 'votes', 'counter');
    await updateDoc(docRef, { count: increment(1) });
    const docSnap = await getDoc(docRef);
    setCount(docSnap.data().count);

    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    }

    setConfetti(true);
    setTimeout(() => setConfetti(false), 3000);
  };

  const handleAuth = async () => {
    try {
      await signInWithEmailAndPassword(auth, beyzaEmail, inputCode);
      setBeyzaAuth(true);
    } catch (error) {
      alert(msgs.onlyBeyza);
    }
  };

  const handleAudioEnd = () => {
    setIsPlaying(false); // Şarkı bittiğinde durumu güncelle
  };

  const msgs = messages[language];

  return (
    <Container>
      <Title>{msgs.title}</Title>
      <VoteButton onClick={handleVote} disabled={forgiven}>{msgs.forgiveButton}</VoteButton>
      {confetti && <Confetti width={width} height={height} />}
      <p>{msgs.totalVotes}: {count === null ? msgs.loading : count}</p>
      {beyzaAuth ? (
        forgiven ? (
          <ForgiveMessage>{msgs.forgiveMessage}</ForgiveMessage>
        ) : (
          <VoteButton onClick={() => setForgiven(true)}>{msgs.forgiveMessage}</VoteButton>
        )
      ) : (
        <AuthContainer>
          <Input
            disabled
            type="password"
            value={inputCode}
            onChange={(e) => setInputCode(e.target.value)}
            placeholder={msgs.placeholder}
          />
          <VoteButton disabled onClick={handleAuth}>{msgs.verifyButton}</VoteButton>
        </AuthContainer>
      )}
      <audio ref={audioRef} src="https://prodigits.co.uk/content2/mp3-ringtones/tone/2015/original/pleaseforg_vsh3csox.mp3" onEnded={handleAudioEnd} />
    </Container>
  );
};

export default HomePage;
